import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { OnboardingSignUp } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { SignUpFormValues } from './SignUpForm.interface'
import { SignUpSchema } from './SignUpForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import parsePhoneNumber from 'libphonenumber-js'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { TOS } from 'src/components/shared/TOS'

interface Props {
  partnerName: string
  partnerMarketId: string
  onboardingTemplateId: string
  tagNames: string[]
  onSuccess(phone: string): void
}

const initialValues: SignUpFormValues = {
  phone: '',
  email: '',
  firstName: '',
  lastName: '',
}

const PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const SignUpForm = ({
  partnerName,
  partnerMarketId,
  onboardingTemplateId,
  tagNames,
  onSuccess,
}: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: SignUpFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      const parsedPhone = parsePhoneNumber(values.phone, 'US')

      const phone = parsedPhone?.formatInternational().replace(/\s+/g, '') || values.phone

      const params: any = {
        phone,
        partnerName,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        partnerMarketId,
        onboardingTemplateId,
      }

      if (tagNames.length) {
        params.tagNames = tagNames
      }

      await createAsyncAction(dispatch, OnboardingSignUp.request(params))

      onSuccess(phone)
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={SignUpSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <FormikTextField
            name="firstName"
            css={tw`w-full mb-4`}
            label="First name*"
            placeholder="Enter your first name"
          />
          <FormikTextField
            name="lastName"
            css={tw`w-full mb-4`}
            label="Last name*"
            placeholder="Enter your last name"
          />
          <FormikTextField
            name="email"
            css={tw`w-full mb-8`}
            label="Email address*"
            placeholder="Enter your email address"
          />
          <FormikTextField
            name="phone"
            css={tw`w-full mb-4`}
            mask={PHONE_MASK}
            label="Phone number*"
            placeholder="Enter your phone number"
          />
          <InfoContainer>
            We will send a verification code to the phone number you provided.
          </InfoContainer>
          <TOS css={tw`mb-6 mt-6`} />
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Start onboarding</span>
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
