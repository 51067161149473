import React, { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { CircleRightMajor, MobileHamburgerMajor } from '@shopify/polaris-icons'
import { Button } from '../shared/Button'
import { MobileMenu } from './MobileMenu'
import { GetHelpModal } from './GetHelpModal'
import { LogoutModal } from './LogoutModal'
import {
  getContractorPortalAuthToken,
  getOnboardingAuthToken,
  getRecollectionAuthToken,
} from 'src/selectors/auth'
import { useSelector } from 'react-redux'
import { getContractorPortalPartners } from 'src/selectors/contractorPortal'
import RecollectIcon from 'src/assets/icons/recollect-white.svg'
import { SwitchPartnerModal } from './SwitchPartnerModal'

interface Props {
  flow: 'onboarding' | 'recollect' | 'contractorPortal'
}

const tokenSelectors = {
  onboarding: getOnboardingAuthToken,
  recollect: getRecollectionAuthToken,
  contractorPortal: getContractorPortalAuthToken,
}

const SettingsComponent = ({ flow }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLogoutOpen, setIsLogoutOpen] = useState(false)
  const [isGetHelpOpen, setIsGetHelpOpen] = useState(false)
  const [isSwitchPartnerOpen, setIsSwitchPartnerOpen] = useState(false)

  const contractorPortalPartners = useSelector(getContractorPortalPartners)

  const authToken = useSelector(tokenSelectors[flow])

  const openMenu = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const openLogout = useCallback(() => {
    setIsLogoutOpen(true)
  }, [])

  const closeLogout = useCallback(() => {
    setIsLogoutOpen(false)
  }, [])

  const openGetHelp = useCallback(() => {
    setIsGetHelpOpen(true)
  }, [])

  const closeGetHelp = useCallback(() => {
    setIsGetHelpOpen(false)
  }, [])

  const openSwitchPartner = useCallback(() => {
    setIsSwitchPartnerOpen(true)
  }, [])

  const closeSwitchPartner = useCallback(() => {
    setIsSwitchPartnerOpen(false)
    setIsMenuOpen(false)
  }, [])

  return (
    <>
      <Button
        leftIcon={<MobileHamburgerMajor css={tw`mx-1`} fill="white" width={20} height={20} />}
        onClick={openMenu}
        size="medium"
        className={tw`block sm:hidden`}
      />
      <div css={tw`sm:block hidden`}>
        {/* <Button
          leftIcon={<ChatMajor fill="white" width={20} height={20} />}
          color="dark"
          size="medium"
          onClick={openGetHelp}
        >
          Get Help
        </Button> */}
        {flow === 'contractorPortal' && contractorPortalPartners.length ? (
          <Button
            leftIcon={<img src={RecollectIcon} />}
            color="dark"
            size="medium"
            onClick={openSwitchPartner}
          >
            Switch Partner
          </Button>
        ) : null}
        {authToken && (
          <Button
            leftIcon={<CircleRightMajor fill="white" width={20} height={20} />}
            size="medium"
            onClick={openLogout}
            className={tw`ml-4`}
          >
            Log out
          </Button>
        )}
      </div>
      {authToken && (
        <MobileMenu
          isOpen={isMenuOpen}
          onClose={closeMenu}
          onLogout={openLogout}
          onGetHelp={openGetHelp}
          onSwitchPartner={openSwitchPartner}
        />
      )}
      <GetHelpModal isOpen={isGetHelpOpen} onClose={closeGetHelp} />
      <LogoutModal flow={flow} isOpen={isLogoutOpen} onClose={closeLogout} />
      <SwitchPartnerModal isOpen={isSwitchPartnerOpen} onClose={closeSwitchPartner} />
    </>
  )
}

export const Settings = memo(SettingsComponent)
