import { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import { ContractorPortalInvoice } from 'src/interfaces/contractorPortal'
import tw from 'twin.macro'
import { ImportMinor, ViewMinor } from '@shopify/polaris-icons'
import { MenuActionItem } from 'src/interfaces/ui'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch } from 'react-redux'
import { ContractorPortalFetchInvoice } from 'src/constants/actionTypes'
import dayjs from 'dayjs'
import { ActionButton } from '../shared/ActionButton'
import { ActionMenu } from '../shared/ActionMenu'
import { showToast } from 'src/utils/toast'
import { ContractorPortalInvoiceModal } from './ContractorPortalInvoiceModal'

interface Props {
  invoice: ContractorPortalInvoice
  isFirstItem?: boolean
}

const ContractorPortalInvoiceItemComponent = ({ invoice, isFirstItem }: Props) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invoiceUrl, setInvoiceUrl] = useState<any>(null)
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState<boolean>(false)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const fetchInvoice = useCallback(async () => {
    setIsLoading(true)

    try {
      const buffer: any = await createAsyncAction(
        dispatch,
        ContractorPortalFetchInvoice.request({
          invoiceId: invoice.id,
        }),
      )

      const blob = new Blob([buffer], { type: 'application/pdf' })

      setInvoiceUrl(URL.createObjectURL(blob))
    } catch (err) {
      showToast('Failed to load invoice', {
        variant: 'error',
      })
    }

    setIsLoading(false)
  }, [])

  const handleAction = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      const anchor = event.currentTarget

      if (!invoiceUrl) {
        await fetchInvoice()
      }

      setAnchorEl(anchor)
    },
    [invoiceUrl],
  )

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const closeInvoiceModal = useCallback(() => {
    setIsInvoiceModalOpen(false)
  }, [])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    const res = [
      {
        label: 'View invoice',
        icon: <ViewMinor width={20} height={20} />,
        onClick: async () => {
          setIsInvoiceModalOpen(true)

          handleCloseMenu()
        },
      },
      {
        label: 'Download invoice',
        icon: <ImportMinor width={20} height={20} />,
        downloadHref: invoiceUrl,
        onClick: async () => {
          handleCloseMenu()
        },
      },
    ]

    return res
  }, [invoice, invoiceUrl, fetchInvoice, handleCloseMenu])

  return (
    <div
      css={[
        tw`p-4 flex items-center bg-white border-0 border-t border-solid border-[#EDEDED] justify-between`,
        isFirstItem && tw`border-none`,
      ]}
    >
      <div css={tw`flex items-center`}>
        <div>
          <Typography variant="h4" css={tw`mb-0.5`}>
            {`$ ${(invoice.netAmount / 100).toLocaleString()}`}
          </Typography>
          <Typography color="#847E96" css={tw`mb-0.5`}>
            {dayjs(invoice.payoutDate).format('MMMM DD, YYYY')}
          </Typography>
        </div>
      </div>
      <ActionButton
        loading={isLoading}
        selected={!!anchorEl}
        color="grey"
        text="Actions"
        onClick={handleAction}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
      <ContractorPortalInvoiceModal
        isOpen={isInvoiceModalOpen}
        invoice={invoiceUrl}
        onClose={closeInvoiceModal}
      />
    </div>
  )
}

export const ContractorPortalInvoiceItem = memo(ContractorPortalInvoiceItemComponent)
