import Menu from '@mui/material/Menu'
import React, { memo } from 'react'
import tw from 'twin.macro'
import { PopoverOrigin, Typography } from '@mui/material'
import { MenuActionItem } from 'src/interfaces/ui'

interface Props {
  items: MenuActionItem[]
  isOpen?: boolean
  anchorEl?: any
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  itemClassName?: any
  onClose(): void
}

const ActionMenuComponent = ({
  items,
  isOpen,
  anchorEl,
  anchorOrigin,
  itemClassName,
  transformOrigin,
  onClose,
}: Props) => {
  return (
    <Menu
      id="menu-list"
      css={tw`mt-1.5 py-2`}
      open={Boolean(isOpen)}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      className="rounded-lg"
    >
      {items.map(({ label, color, icon, rightIcon, className, downloadHref, onClick }) => (
        <a
          key={label}
          href={downloadHref}
          download={!!downloadHref}
          css={tw`text-black no-underline`}
        >
          <div
            key={label}
            css={[
              tw`py-2 px-3 flex hover:bg-[#F6F6F6] active:bg-[#B4AFC0] cursor-pointer`,
              itemClassName,
              className,
            ]}
            onClick={onClick}
          >
            {icon}
            <Typography css={icon && tw`ml-2`} variant="subtitle1" color={color}>
              {label}
            </Typography>
            {rightIcon}
          </div>
        </a>
      ))}
    </Menu>
  )
}

export const ActionMenu = memo(ActionMenuComponent)
